<template>
  <v-container fluid>
    <v-card flat height="800">
      <v-card-title>
        <v-spacer />

        404
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-sheet min-height="20vh" class="text-center d-flex align-center">
          <v-spacer />
          <div>
            <p>page not found.</p>
          </div>
          <v-spacer />
        </v-sheet>
      </v-card-text>

      <v-card-actions> </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},

  computed: {},
  data: () => ({
    initializing: true,
  }),
  methods: {
    async onLoadAsync() {},
  },
  //
  async mounted() {
    //
    await this.onLoadAsync();
  },
};
</script>
